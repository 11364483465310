import style from "../css/Footer.module.css";
import logo from "../image/component/logo_column.svg";
import logoE from "../image/component/logoE_column.svg";
import adminIcon from "../image/component/admin_icon.svg";
import Portal from "./Portal";
import AdminModal from "../AdminComponents/AdminModal";
import { useState } from "react";

export default function Footer({isEn, admin, setAdmin, showAdmin, setShowAdmin, adminField, setAdminField}) {
    function chkAdmin() {
        if(admin) {
            alert("로그아웃이 완료되었습니다.");
            setAdmin(false)
            setShowAdmin(false)
            window.localStorage.removeItem("login_access")
        } else {
            setAdminField('login')
            setShowAdmin(true);
        }
    }

    return(
        <div className={style.wrap}>
            <div className={style.container}>
                <div className={style.footerLogo}>
                    <img src={`${isEn ? logoE : logo}`} alt="footer-logo" id={style.footerLogo}></img>
                    <img src={adminIcon} alt="adminIcon" id={style.adminBtn} onClick={chkAdmin}></img>
                </div>
                <div className={style.footerInfo}>
                    {
                        isEn ? 
                        <div className={style.footerLeft}>
                            <span className={style.footerAddress}>Corporation HANJOO A.R.T.</span>
                            <span className={style.footerAddress}>12F, 21, Godeokbizvalley-ro 2ga-gil, Gangdong-gu, Seoul, Korea</span>
                        </div>
                        :
                        <div className={style.footerLeft}>
                            <span className={style.footerAddress}>(05203)서울특별시 강동구 고덕비즈밸리로2가길 21, 12층(고덕동, 알에프텍빌딩) 한주에이알티 주식회사</span>
                            <span className={style.footerCompany}>대표이사 : 이정기 | 사업자등록번호 : 139-81-01169<br></br>전화번호: 1522-0011 | FAX: 02-332-1927</span>
                        </div>
                    }
                    <div className={style.footerRight}>
                        <span className={style.footerCompany}>
                            COPYRIGHT (C) 2023 HANJOO A.R.T. ALL RIGHT RESERVED.
                        </span>
                    </div>
                </div>
            </div>
            <Portal>
                {
                    showAdmin &&
                    <AdminModal admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField}></AdminModal>
                }
            </Portal>
        </div>
    )
}