import { useEffect } from "react";
import { Link } from "react-router-dom";

import style from "../css/Popup.module.css";
import exit from "../image/popup/exit.svg"
import logo from "../image/component/header_logo.svg";

export default function Popup({setPopup, popup}) {
    function closeToday() {
        const expire = new Date();
        const expireDate = expire.getDate();

        localStorage.setItem('VisitCookie', expireDate);
        setPopup(false);
    }

    return(
        <div className={style.popupContainer}>
            <div className={style.popupHeader}>
                <img src={exit} alt="popup-exit" onClick={() => setPopup(false)}></img>
            </div>
            <div className={style.popupBody}>
                <div className={style.popupCnt}>
                    {/*<img src={logo} alt="logo" id={style.bodyLogo} />*/}
                    <p>기준일 설정 공고</p>
                    <div className={style.popupTxt}>
                        상법 제354조 및 당사 정관 규정에 따라 2024년 09월 20일 현재 주주명부에 기재되어 있는 주주에게 기준일 설정을 공고합니다.
                    </div>
                    <div className={style.popupInfo}>
                        <span>1. 주주확정 기준일 : 2023년 08월 23일</span>
                        <span>2023년 08월 08일</span>
                        <span>한주에이알티 주식회사</span>
                        <span>서울시 강동구 고덕비즈밸리로2가길 21, 12층</span>
                        <span>대표이사 이 정 기</span>
                    </div>
                </div>
            </div>
            <div className={style.popupFooter}>
                <span onClick={closeToday}>오늘하루 그만보기</span>
            </div>
        </div>
    )
}